export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);
  return false;
};

export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
  if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
    return null;
  }

  // const { query, hash } = location;
  // if (query && query.eapp_id) {
  //   console.log('Doing re-direct for eapp_id');
  //   console.log('Hash: ', hash);
  //   const pathPath = `${location.pathname}/?eapp_id=${
  //     query.eapp_id
  //   }&eapp_uuid=${query.eapp_uuid}${hash && hash !== '' ? hash : ''}`;
  //   window.location = pathPath;
  // }

  // window.page.path = window.location.pathname;

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined;
    window.gtag(`event`, `page_view`, { page_path: pagePath });
  };

  const delayOnRouteUpdate = 0;

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => setTimeout(sendPageView, delayOnRouteUpdate));
    });
  } else {
    // Delay by 32ms to simulate 2 requestOnAnimationFrame calls
    setTimeout(sendPageView, 32 + delayOnRouteUpdate);
  }

  return null;
};
